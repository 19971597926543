const { createApp} = Vue

createApp({
    data() {
        return {
            item: '',
            isLoaded: false,
            events: [],
            leagues: []
        }
    },

    mounted: async function (){

        var date = new Date()
        let year = date.getFullYear()
        let day = date.getDate() < 10 ? `0${date.getDate()}`: date.getDate()
        let month = date.getMonth()+1 < 10 ? `0${date.getMonth() + 1}`: date.getMonth()+1

        let fullDate = `${year}-${month}-${day}`

        await this.getEvents(fullDate)
    },

    methods: {
        async getEvents(date){
            try{
               
                let url = `https://taloscoreapi.onrender.com/api/v1/fixtures/${date}`
                const request = await axios.get(url)
                if(request.data){
                    this.isLoaded = true
                }
        
                const arraysByLeagueId = {}

                request.data.forEach(element => {
                    const leagueId = element.league.id
                    if(!arraysByLeagueId.hasOwnProperty(leagueId)){
                        arraysByLeagueId[leagueId] = []
                    }

                    arraysByLeagueId[leagueId].push(element)
                });

                this.events = arraysByLeagueId
        
                
            }catch(error){
                // console.log(error.message)
            }
        },

        updateInfo(event){
            this.item = event
            // console.log(event)
        },

        async getLeagues(){
            try{
                const url = "https://taloscoreapi.onrender.com/api/v1/leagues"
                const request = await axios.get(url)
                this.leagues = request.data

                const newArr = this.leagues.map(function(item){
                    return {id: item.league.id, name: item.league.name}
                })
                
            }catch(error){
                // console.log(error.message)
            }
        },

        parseTime(date){
            const parseDate = new Date(date)
            const nowDate = new Date()
            let hour = "";
            let minute = ""
            let day = parseDate.getDate() < 10 ? `0${parseDate.getDate()}` : parseDate.getDate()
            let month = parseDate.getMonth()+1 < 10 ? `0${parseDate.getMonth()}` : parseDate.getMonth()+1
            let year = parseDate.getFullYear()

            let nowDay = nowDate.getDate() < 10 ? `0${nowDate.getDate()}` : nowDate.getDate()
            let nowMonth = nowDate.getMonth()+1 < 10 ? `0${nowDate.getMonth()}` : nowDate.getMinutes()+1
            let nowYear = nowDate.getFullYear()

            let now = `${nowDay}-${nowMonth}-${nowYear}`
            let selectedDate = `${day}-${month}-${year}`


            if(parseDate.getHours() < 9){
                hour = `0${parseDate.getHours()}`
            }else{
                hour = parseDate.getHours();
            }

            if(parseDate.getMinutes() == 0){
                minute = "00"
            }else{
                minute = parseDate.getMinutes()
            }

            if(selectedDate !== now){
                const time =  `${selectedDate} \n\n${hour}:${minute}`
                return time
            }

            const time =  `${hour}:${minute}`
            return time
        },

        async loadLiveEvents(){

            try{
                this.events = [];
                this.isLoaded = false
                let url = "https://taloscoreapi.onrender.com/api/v1/fixtures/live"
                const request = await axios.get(url)
                if(request.data){
                    this.isLoaded = true;
                }

                const arraysByLeagueId = {}

                request.data.forEach(element => {
                    const leagueId = element.league.id
                    if(!arraysByLeagueId.hasOwnProperty(leagueId)){
                        arraysByLeagueId[leagueId] = []
                    }

                    arraysByLeagueId[leagueId].push(element)
                });

                this.events = arraysByLeagueId

            }catch(e){
                // console.log(e)
            }

        },

        async loadTodayEvents(){
            try{

                this.events = []
                this.isLoaded = false
                var date = new Date()
                let year = date.getFullYear()
                let day = date.getDate() < 10 ? `0${date.getDate()}`: date.getDate()
                let month = date.getMonth()+1 < 10 ? `0${date.getMonth() + 1}`: date.getMonth()+1
        
                let fullDate = `${year}-${month}-${day}`
        
                await this.getEvents(fullDate)

            }catch(e){
                // console.log(e)
            }
        },

        async loadYesterdayEvents(){
            try{

                this.events = []
                this.isLoaded = false
                var date = new Date()

                let year = date.getFullYear()
                let day = date.getDate()-1 < 10 ? `0${date.getDate()-1}`: date.getDate()-1
                let month = date.getMonth()+1 < 10 ? `0${date.getMonth() + 1}`: date.getMonth()+1
            

                let fullDate = `${year}-${month}-${day}`
                await this.getEvents(fullDate)
                
            }catch(e){
                // console.log(e)
            }
        },

        async loadTomorrowEvents(){
            try{

                this.events = []
                this.isLoaded = false
                var date = new Date()

                let year = date.getFullYear()
                let day = date.getDate()+1 < 10 ? `0${date.getDate()+1}`: date.getDate()+1
                let month = date.getMonth()+1 < 10 ? `0${date.getMonth() + 1}`: date.getMonth()+1

                let fullDate = `${year}-${month}-${day}`
                await this.getEvents(fullDate)
                
            }catch(e){
                // console.log(e)
            }
        }

    }
}).mount('#app')